import "./topBox.scss"
import {topDealUsers} from "../../data.js"
import {useEffect, useState} from "react";
import axios from "axios";

const TopTranslatorsBox = () => {

    const [topTranslators, setTopTranslators] = useState([]);

    useEffect(() => {
        const fetchTopTranslators = async () => {
            try {
                const {data} = await axios.get('https://balkanflix-server.vercel.app/api/auth/getTopTranslators');
                setTopTranslators(data.topTranslators);
            } catch (error) {
                console.error("Error fetching top users:", error);
            }
        };

        fetchTopTranslators();
    }, []);

    return (
        <div className="topBox">
            <h1>Top Prevodioci🔥</h1>
            <div className="list">
                {topTranslators.map(user => (
                    <div className="listItem" key={user._id}>
                        <div className="topbox_user">
                            <img src={user.pfp} alt="pfp" />
                            <div className="userTexts">
                                <span className="username_dashboard">{user.username}</span>
                            </div>
                        </div>
                        <span className="amount">{user.totalEpisodesTranslated}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TopTranslatorsBox