import React, { useState } from "react";
import "./terms.scss";

const Terms = () => {
    const [language, setLanguage] = useState("sr");

    const termsContent = {
        sr: {
            title: "Uslovi korišćenja",
            lastUpdated: "Poslednje izmene: Januar 06, 2025",
            intro: "Dobrodošli na BalkanFlix! Molimo vas da pažljivo pročitate sledeće uslove pre korišćenja sajta.",
            sections: [
                {
                    heading: "1. Pristup i korišćenje",
                    content:
                        "Korišćenje sajta podrazumeva saglasnost sa sledećim uslovima. Ukoliko se ne slažete sa pravilima, molimo vas da ne koristite našu platformu.",
                },
                {
                    heading: "2. Autorska prava",
                    content:
                        "Sav sadržaj na sajtu je zaštićen autorskim pravima. Dalje prenošenje sadržaja je dozvoljeno samo uz prethodnu saglasnost. Kontaktirajte nas na: contact.balkanflix@gmail.com",
                },
                {
                    heading: "3. Zabrana uvredljivog sadržaja",
                    content:
                        "Korisnici su obavezni da se uzdržavaju od postavljanja uvredljivog sadržaja u chat sekciji sajta. Prekršaji će biti sankcionisani.",
                },
                {
                    heading: "4. Prikupljanje podataka",
                    content:
                        "Prikupljamo podatke kao što su ime i email radi poboljšanja korisničkog iskustva. Vaša privatnost nam je važna.",
                },
            ],
        },
        en: {
            title: "Terms of Use",
            lastUpdated: "Last updated: January 06, 2025",
            intro: "Welcome to BalkanFlix! Please read the following terms carefully before using the site.",
            sections: [
                {
                    heading: "1. Access and Use",
                    content:
                        "By using the site, you agree to the following terms. If you do not agree, please refrain from using our platform.",
                },
                {
                    heading: "2. Copyright",
                    content:
                        "All content on the site is protected by copyright. Redistribution is allowed only with prior consent. Contact us at: contact.balkanflix@gmail.com",
                },
                {
                    heading: "3. Prohibition of Offensive Content",
                    content:
                        "Users are prohibited from posting offensive content in the site's chat section. Violations will be sanctioned.",
                },
                {
                    heading: "4. Data Collection",
                    content:
                        "We collect data such as name and email to improve user experience. Your privacy is important to us.",
                },
            ],
        },
    };

    const changeLanguage = (lang) => setLanguage(lang);

    return (
        <div className="terms">
            <div className="language-switch">
                <button
                    className={language === "sr" ? "active" : ""}
                    onClick={() => changeLanguage("sr")}
                >
                    SR
                </button>
                <button
                    className={language === "en" ? "active" : ""}
                    onClick={() => changeLanguage("en")}
                >
                    EN
                </button>
            </div>
            <h1>{termsContent[language].title}</h1>
            <p className="last-updated">{termsContent[language].lastUpdated}</p>
            <p className="intro">{termsContent[language].intro}</p>
            {termsContent[language].sections.map((section, index) => (
                <div key={index} className="section">
                    <h2>{section.heading}</h2>
                    <p>{section.content}</p>
                </div>
            ))}
        </div>
    );
};

export default Terms;
