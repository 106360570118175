import React from 'react';

import './footer.scss';

import { Link } from 'react-router-dom';

// import bg from '../../assets/footer-bg.webp';

import { AiFillInstagram } from "react-icons/ai";
import { PiTiktokLogoFill } from "react-icons/pi";
import { SiGmail } from "react-icons/si";
import bg from "../../assets/footer-bg.webp";

const Footer = () => {
    return (
        <div className="footer" style={{backgroundImage: `url("/Novogodisnji-footer.png")`}}>
            <div className="footer__content ">
                <div className="footer__content__logo">
                    <div className="logo">
                        <img src="/Balkanflix_novogodisnji.png" alt="logo" />
                        <Link to="/">
                            <div className="bf">
                                <div className="balkan">Balkan</div>
                                <div className="flix">Flix</div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="soc-mda">
                        <Link to="https://www.instagram.com/_balkanflix?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" aria-label="Instagram"><AiFillInstagram/></Link>
                        <Link to="https://www.tiktok.com/@balkanflix.official?is_from_webapp=1&sender_device=pc" aria-label="Tiktok"><PiTiktokLogoFill/></Link>
                        <Link to="mailto:contact.balkanflix@gmail.com" aria-label="Mail"><SiGmail/></Link>
                </div>
                <div className="footer__content__menus">
                    <div className="footer__content__menu">
                        <Link className="menu-item" to="/">Početna</Link>
                        <Link className="menu-item" to="/o-nama" target="_top">O nama</Link>
                        <Link className="menu-item" to="/uslovi-koriscenja" target="_top">Uslovi koriscenja</Link>
                    </div>

                    <div>
                        <h3>Balkanflix © 2025 | Sva prava zagarantovana</h3>
                        <h4>Balkanflix® - sajt koji strimuje anime sa domaćim prevodom.</h4>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Footer;