import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateTranslator = () => {
    const isTranslator = localStorage.getItem("isTranslator");

    // Check if isAdmin is explicitly the string "false"
    if (isTranslator === "false" || !isTranslator) {
        return <Navigate to="/login" />;
    }

    return <Outlet />;
};

export default PrivateTranslator;