
export const menu = [
    {
        id: 1,
        title: "glavno",
        listItems: [
            {
                id: 1,
                title: "Profil",
                url: "/dev",
                icon: "user.svg",
            },
            // {
            //     id: 2,
            //     title: "Titlovi",
            //     url: "/dev/subtitles",
            //     icon: "log.svg",
            // },
            {
                id: 2,
                title: "Epizode",
                url: "/dev/episodes",
                icon: "movie.svg",
            },
        ],
    },
    {
        id: 2,
        title: "liste",
        listItems: [
            {
                id: 1,
                title: "Prevodioci",
                url: "/dev/translators",
                icon: "user.svg",
            },
            {
                id: 2,
                title: "Obavestenja",
                url: "/dev/notifications",
                icon: "notifications.svg",
            }
            // {
            //     id: 2,
            //     title: "Serijali",
            //     url: "/dev/series",
            //     icon: "movie.svg",
            // },

        ],
    },
    {
        id: 3,
        title: "informacije",
        listItems: [
            {
                id: 2,
                title: "Beleške",
                url: "/dev/notes",
                icon: "note.svg",
            },
            {
                id: 4,
                title: "Kalendar",
                url: "/dev/calendar",
                icon: "calendar.svg",
            },
        ],
    },
    {
        id: 4,
        title: "Alatke",
        listItems: [
            {
                id: 1,
                title: "Nyaa.si",
                url: "https://nyaa.si/",
                icon: "element.svg",
            },
            {
                id: 2,
                title: "Aegisub",
                url: "https://aegisub.org/downloads/",
                icon: "form.svg",
            },
            {
                id: 3,
                title: "VLC Media",
                url: "https://www.videolan.org/vlc/download-windows.html",
                icon: "setting.svg",
            },
            {
                id: 4,
                title: "MKVToolNix",
                url: "https://mkvtoolnix.download/downloads.html",
                icon: "note.svg",
            },
        ],
    },
];

export const topDealUsers = [
    {
        id: 1,
        img: "https://images.pexels.com/photos/8405873/pexels-photo-8405873.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
        username: "Elva McDonald",
        email: "elva@gmail.com",
        amount: "3.668",
    },
    {
        id: 2,
        img: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1600",
        username: "Linnie Nelson",
        email: "linnie@gmail.com",
        amount: "3.256",
    },
    {
        id: 3,
        img: "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=1600",
        username: "Brent Reeves",
        email: "brent@gmail.com",
        amount: "2.998",
    },
    {
        id: 4,
        img: "https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=1600",
        username: "Adeline Watson",
        email: "adeline@gmail.com",
        amount: "2.512",
    },
    {
        id: 5,
        img: "https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&w=1600",
        username: "Juan Harrington",
        email: "juan@gmail.com",
        amount: "2.134",
    },
    {
        id: 6,
        img: "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&w=1600",
        username: "Augusta McGee",
        email: "augusta@gmail.com",
        amount: "1.932",
    },
    {
        id: 7,
        img: "https://images.pexels.com/photos/874158/pexels-photo-874158.jpeg?auto=compress&cs=tinysrgb&w=1600",
        username: "Angel Thomas",
        email: "angel@gmail.com",
        amount: "1.560",
    },
];
export const singleUser = {
    id: 1,
    title: "John Doe",
    img: "https://images.pexels.com/photos/17397364/pexels-photo-17397364.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
    info: {
        username: "Johndoe99",
        fullname: "John Doe",
        email: "johndoe@gmail.com",
        phone: "123 456 789",
        status: "verified",
    },
    chart: {
        dataKeys: [
            { name: "visits", color: "#82ca9d" },
            { name: "clicks", color: "#8884d8" },
        ],
        data: [
            {
                name: "Sun",
                visits: 4000,
                clicks: 2400,
            },
            {
                name: "Mon",
                visits: 3000,
                clicks: 1398,
            },
            {
                name: "Tue",
                visits: 2000,
                clicks: 3800,
            },
            {
                name: "Wed",
                visits: 2780,
                clicks: 3908,
            },
            {
                name: "Thu",
                visits: 1890,
                clicks: 4800,
            },
            {
                name: "Fri",
                visits: 2390,
                clicks: 3800,
            },
            {
                name: "Sat",
                visits: 3490,
                clicks: 4300,
            },
        ],
    },
    activities: [
        {
            text: "John Doe purchased Playstation 5 Digital Edition",
            time: "3 day ago",
        },
        {
            text: "John Doe added 3 items into their wishlist",
            time: "1 week ago",
        },
        {
            text: "John Doe purchased Sony Bravia KD-32w800",
            time: "2 weeks ago",
        },
        {
            text: "John Doe reviewed a product",
            time: "1 month ago",
        },
        {
            text: "John Doe added 1 items into their wishlist",
            time: "1 month ago",
        },
        {
            text: "John Doe reviewed a product",
            time: "2 months ago",
        },
    ],
};
export const singleProduct = {
    id: 1,
    title: "Playstation 5 Digital Edition",
    img: "https://store.sony.com.au/on/demandware.static/-/Sites-sony-master-catalog/default/dw1b537bbb/images/PLAYSTATION5W/PLAYSTATION5W.png",
    info: {
        productId: "Ps5SDF1156d",
        color: "white",
        price: "$250.99",
        producer: "Sony",
        export: "Japan",
    },
    chart: {
        dataKeys: [
            { name: "visits", color: "#82ca9d" },
            { name: "orders", color: "#8884d8" },
        ],
        data: [
            {
                name: "Sun",
                visits: 4000,
                orders: 2400,
            },
            {
                name: "Mon",
                visits: 3000,
                orders: 1398,
            },
            {
                name: "Tue",
                visits: 2000,
                orders: 3800,
            },
            {
                name: "Wed",
                visits: 2780,
                orders: 3908,
            },
            {
                name: "Thu",
                visits: 1890,
                orders: 4800,
            },
            {
                name: "Fri",
                visits: 2390,
                orders: 3800,
            },
            {
                name: "Sat",
                visits: 3490,
                orders: 4300,
            },
        ],
    },
    activities: [
        {
            text: "John Doe purchased Playstation 5 Digital Edition",
            time: "3 day ago",
        },
        {
            text: "Jane Doe added Playstation 5 Digital Edition into their wishlist",
            time: "1 week ago",
        },
        {
            text: "Mike Doe purchased Playstation 5 Digital Edition",
            time: "2 weeks ago",
        },
        {
            text: "Anna Doe reviewed the product",
            time: "1 month ago",
        },
        {
            text: "Michael Doe added Playstation 5 Digital Edition into their wishlist",
            time: "1 month ago",
        },
        {
            text: "Helen Doe reviewed the product",
            time: "2 months ago",
        },
    ],
};