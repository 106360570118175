import './dashboard.scss';
import TopTranslatorsBox from "../topBox/TopBox";
import TranslatorsChat from "../tsChat/tsChat";
import SubtitleUploader from "../subtitleUploader/subtitleUploader";

const TranslatorProfil = () => {

    return (
        <div className="home">
            <div className="box box1">
                <TopTranslatorsBox/>
            </div>
            <div className="box box2">
                <TranslatorsChat/>
            </div>
            <div className="box box2">
                <SubtitleUploader/>
            </div>
        </div>
    );
};

export default TranslatorProfil;
