import React, {useEffect, useState} from "react";
import "./notes.scss";
import client, { DATABASE_ID, COLLECTION_ID, databases } from "../notes/appwriteConfig/appwriteConfig";
import { ID, Query } from "appwrite";

const Notes = () => {

    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState("");
    const [cooldown, setCooldown] = useState(false);

    const username = localStorage.getItem("username") || "Guest";
    const avatar = localStorage.getItem("pfp") || "default-avatar-url.webp";

    useEffect(() => {
        fetchNotes();

        let unsubscribe;

        const connectRealtime = () => {
            unsubscribe = client.subscribe(`databases.${DATABASE_ID}.collections.${COLLECTION_ID}.documents`, response => {
                if (response.events.includes("databases.*.collections.*.documents.*.create")) {
                    setNotes(prev => [response.payload, ...prev]);
                }
            });
        };

        connectRealtime();

        return () => {
            if (unsubscribe) unsubscribe();
        };
    }, []);

    const fetchNotes = async () => {
        try {
            const response = await databases.listDocuments(
                DATABASE_ID,
                COLLECTION_ID,
                [Query.orderDesc("$createdAt"), Query.limit(100)]
            );
            setNotes(response.documents);
        } catch (error) {
            console.error("Error fetching notes:", error);
        }
    };

    const addNote = async () => {
        if (newNote.trim() && !cooldown) {
            setCooldown(true);

            const payload = {
                username,
                pfp: avatar,
                body: newNote,
                date: new Date().toLocaleString(),
            };

            try {
                await databases.createDocument(DATABASE_ID, COLLECTION_ID, ID.unique(), payload);
                setNewNote("");
            } catch (error) {
                console.error("Error adding note:", error);
            }

            setTimeout(() => setCooldown(false), 1000);
        }
    };

    // const addNote = () => {
    //     if (newNote.trim()) {
    //         const newEntry = {
    //             username: "",
    //             avatar: "",
    //             text: newNote,
    //             date: new Date().toLocaleString(),
    //         };
    //         setNotes([newEntry, ...notes]);
    //         setNewNote(""); // Reset input field
    //     }
    // };

    return (
        <div className="notes-page">
            <div className="notes-container">
                <h1>📌 Beleške</h1>
                <div className="add-note">
                    <input
                        type="text"
                        value={newNote}
                        onChange={(e) => setNewNote(e.target.value)}
                        placeholder="Napiši novu belešku..."
                    />
                    <button onClick={addNote} disabled={cooldown} >⚔️</button>
                </div>
                <ul className="notes-list">
                    {notes.map((note) => (
                        <li key={note.$id}>
                            <img src={note.pfp} alt="Avatar" className="avatar" />
                            <div className="note-content">
                                <strong className="username">{note.username}</strong>
                                <p>{note.body}</p>
                                <span className="note-date">{note.date}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Notes;
