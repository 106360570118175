import React, { useEffect, useState } from "react";
import "./subtitleUploader.scss";
import { storage } from "./appwriteConfig/appwriteConfig";
import { ID } from "appwrite";
import { FaTrashCan, FaDownload } from "react-icons/fa6";

const SubtitleUploader = () => {
    const [files, setFiles] = useState([]);
    const [tempFiles, setTempFiles] = useState([]);
    const [episode, setEpisode] = useState("");
    const [series, setSeries] = useState("");

    const handleFileUpload = async (event) => {
        const uploadedFiles = Array.from(event.target.files);

        const promises = uploadedFiles.map(async (file) => {
            try {
                const response = await storage.createFile(
                    "6780fd9e001b9c23a9cc",
                    ID.unique(),
                    file
                );
                return response;
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        });

        await Promise.all(promises);
        fetchUploadedFiles(); // Re-fetch uploaded files
    };

    const fetchUploadedFiles = async () => {
        try {
            const response = await storage.listFiles("6780fd9e001b9c23a9cc");
            setFiles(response.files);
        } catch (error) {
            console.error("Error fetching files:", error);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setTempFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };

    const triggerFileInput = () => {
        document.getElementById("fileInput").click();
    };

    const markFileAsGood = (index) => {
        setFiles((prevFiles) =>
            prevFiles.map((file, i) =>
                i === index ? { ...file, isGood: true } : file
            )
        );
    };

    const removeUploadedFile = async (index) => {
        const fileToDelete = files[index];
        try {
            await storage.deleteFile("6780fd9e001b9c23a9cc", fileToDelete.$id);
            fetchUploadedFiles(); // Refresh the list
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    const downloadFile = async (fileId) => {
        try {
            const downloadUrl = storage.getFileDownload(
                "6780fd9e001b9c23a9cc",
                fileId
            );
            window.open(downloadUrl, "_blank");
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    useEffect(() => {
        fetchUploadedFiles();
    }, []);

    return (
        <div className="subtitle-uploader">
            <h2>Subtitle Uploader</h2>

            <div
                className="upload-box"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={triggerFileInput}
            >
                Drag & drop .SRT or .ASS file, or click to upload
                <input
                    type="file"
                    id="fileInput"
                    accept=".srt,.ass"
                    style={{ display: "none" }}
                    multiple
                    onChange={handleFileUpload}
                />
            </div>

            <div className="uploaded-files">
                <h3>Uploadovani Fajlovi</h3>
                {files.length === 0 ? (
                    <p>Nema uploadovanih fajlova.</p>
                ) : (
                    files.map((file, index) => (
                        <div key={file.$id} className="file-item">
                            <div className="file-info">
                                <span className="file-name">{file.name}</span>
                                {file.isGood && (
                                    <span className="file-status">Good</span>
                                )}
                            </div>
                            <div className="file-actions">
                                <button
                                    className="download-button"
                                    onClick={() => downloadFile(file.$id)}
                                >
                                    <FaDownload />
                                </button>

                                <button
                                    className="remove-button"
                                    onClick={() => removeUploadedFile(index)}
                                >
                                    <FaTrashCan />
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SubtitleUploader;
