import {useEffect, useState} from "react"
import DataTable from "../dataTable/DataTable"
import axios from "axios";
import "./translatorsPage.scss"

const Translators = () => {
    const [translatorRows, setTranslatorRows] = useState([]);

    const columns = [
        // {
        //     field: "id",
        //     headerName: "ID",
        //     width: 100
        // },
        {
            field: "pfp",
            headerName: "Profilna",
            width: 80,
            renderCell: params => {
                return <img src={params.row.pfp || "/noavatar.png"} style={{borderRadius: "50%", height: "35px", width: "35px"}} alt="avatar" />
            }
        },
        {
            field: "username",
            headerName: "Username",
            width: 120,
            type: "string",
            renderCell: (params) => {
                const localStorageUsername = localStorage.getItem("username");
                const isCurrentUser = localStorageUsername === params.row.username;

                // Define a style if the username matches
                const customStyle = isCurrentUser ? { fontWeight: "bold", fontSize: "17px", color: "red", textShadow: "red 1px 0 10px"} : {};

                return <div style={customStyle}>{params.row.username}</div>;
            }
        },
        {
            field: "email",
            type: "string",
            headerName: "Email",
            width: 260
        },
        {
            field: "brojPrevoda",
            headerName: "Broj Prevoda",
            width: 150,
            type: "string"
        },
    ]

    const fetchTranslators = async () => {
        try {
            const {data} = await axios.get('https://balkanflix-server.vercel.app/api/auth/allTranslators');
            const translatorsWithId = data.data.map(user => ({
                ...user,
                id: user._id,
            }));
            setTranslatorRows(translatorsWithId);
        } catch (error) {
            console.error("Failed to fetch users", error);
        }
    };

    useEffect(() => {
        fetchTranslators().then(result => console.log(result));
    }, []);

    return (
        <>
            <div className="users">
                <div className="info">
                    <h1>Prevodioci na sajtu</h1>
                    <button className="add-user-btn" onClick={fetchTranslators}><h4>Refresh</h4></button>
                </div>
                <DataTable slug="users" columns={columns} rows={translatorRows}/>
            </div>
        </>

    );
};

export default Translators;
