import Header from '../Mainscreen/components/header/Header'
import './chat.scss'
import client, { databases, DATABASE_ID, COLLECTION_ID } from './appwriteConfig/appwriteConfig'
import {IoSend} from "react-icons/io5";
import {useEffect, useRef, useState} from "react";
import {ID, Query} from "appwrite"


const Chat = () => {

    console.log("CHAT TEST 2")
//sfafsafsafsafasf
    const [messages, setMessages] = useState([]);
    const [messageBody, setMessageBody] = useState('');
    const messagesEndRef = useRef(null);
    const [shouldReconnect, setShouldReconnect] = useState(true);

    const username = localStorage.getItem('username');

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    const [cooldown, setCooldown] = useState(false); // Cooldown state

    useEffect(() => {
        scrollToBottom();
    }, [messages]); // Dependency array includes messages


    useEffect(() => {
        getMessages();

        let unsubscribe;

        const connectRealtime = () => {
            unsubscribe = client.subscribe(`databases.${DATABASE_ID}.collections.${COLLECTION_ID}.documents`, response => {
                if (response.events.includes("databases.*.collections.*.documents.*.create")) {
                    setMessages(prevState => [...prevState, response.payload]);
                }
            }, error => {
                console.error("Realtime subscription error:", error);
                if (shouldReconnect) {
                    setTimeout(connectRealtime, 5000); // try to reconnect every 5 seconds
                }
            });
        };

        connectRealtime();

        return () => {
            setShouldReconnect(false); // When component unmounts, prevent reconnection
            if (unsubscribe) unsubscribe();
        };
    }, []);

    const getMessages = async ( ) => {
        const res = await databases.listDocuments(
            DATABASE_ID,
            COLLECTION_ID,
            [
                Query.orderAsc('$createdAt'),
                Query.limit(10000)
            ]
            );
        console.log('RES: ', res);
        setMessages(res.documents);
    }

    const formatTime = dateStr => {
        const date = new Date(dateStr);
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (cooldown) return; // Spreči slanje ako je cooldown aktivan

        setCooldown(true); // Aktiviraj cooldown odmah

        const username = localStorage.getItem('username');
        const pfp = localStorage.getItem('pfp');

        let payload = {
            body: messageBody,
            username: username,
            pfp: pfp
        };

        try {
            let res = await databases.createDocument(
                DATABASE_ID,
                COLLECTION_ID,
                ID.unique(),
                payload
            );

            console.log("Sent: ", res);

            setMessageBody('');
        } catch (error) {
            console.error("Error sending message:", error);
        }

        // Postavi cooldown na 3 sekunde
        setTimeout(() => {
            setCooldown(false);
        }, 1000);
    };

    // const deleteMessage = async (message_id) => {
    //     await databases.deleteDocument(
    //         DATABASE_ID,
    //         COLLECTION_ID,
    //         message_id
    //     );
    // }

    return (
        <>
            <Header/>
            <div className="chat">
                <div className="snow"></div>
                <div className="messages">
                    {messages.map(message => (
                        <div className={message.username === username ? "message-my" : "message"} key={message.$id}>
                            <div className="name">
                                <img src={message.pfp} alt="PFP"/>
                                <h3>{message.username}</h3>
                                <p>{formatTime(message.$createdAt)}</p>
                            </div>
                            <div className="message-holder">
                                <p>{message.body}</p>
                            </div>
                        </div>
                    ))}
                    <div ref={messagesEndRef}></div>
                </div>
                <form onSubmit={handleSubmit} className="message-input">
                    <input
                        type="text"
                        placeholder="Unesi tekst..."
                        required
                        maxLength="200"
                        onChange={(e) => {
                            setMessageBody(e.target.value)
                        }}
                        value={messageBody}
                    />
                    <button type="submit" disabled={cooldown}>
                        <IoSend/>
                    </button>
                </form>
            </div>
        </>
    );
};

export default Chat;
