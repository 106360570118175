import { Client, Databases, Account } from "appwrite";

export const PROJECT_ID = '6634fe5f003caa952872'
export const DATABASE_ID = '6634ff8f0015b74e49fc'
export const COLLECTION_ID = '677faa88003d48e77d9f'

const client = new Client();

client.setEndpoint('https://cloud.appwrite.io/v1').setProject('6634fe5f003caa952872');

export const databases = new Databases(client);
export const account = new Account(client)

export default client;