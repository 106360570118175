import React, {useEffect, useState} from 'react';
import "./episodes.css"
import axios from "axios";

const Episodes = () => {

    const [truncatedSeries, setTruncatedSeries] = useState([]);
    const [series, setSeries] = useState([]);
    const [selectedSeries, setSelectedSeries] = useState("");
    const [episode, setEpisode] = useState("");
    const [fileCode, setFileCode] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {data} = await axios.get("https://balkanflix-server.vercel.app/api/content/series");

                const truncatedSeries = data.series.map((s) => ({
                    ...s,
                    title:
                        s.title.length > 45
                            ? `${s.title.substring(0, 45)}...`
                            : s.title,
                }));

                setTruncatedSeries(truncatedSeries);
                setSeries(data.series);
                console.log(truncatedSeries);
            }catch (e) {
                console.error("Error fetching series data:", e);
            }
        }
        fetchData().then(r => console.log(r));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Series:", truncatedSeries);
        console.log("Episode:", episode);
        console.log("File Code:", fileCode);

        const selectedAnime = series.find((anime) => anime.title_params === selectedSeries);
        console.log(selectedAnime, "SELECTED ANIME")
        if (selectedAnime) {
            const img = selectedAnime.img;
            const poster = selectedAnime.poster;
            console.log("SLIKA",img )

            await axios.post("https://balkanflix-server.vercel.app/api/episode/release",
                {
                    series: selectedSeries,
                    episode: episode,
                    fileCode: fileCode,
                    img: img,
                    poster: poster,
                    title: selectedAnime.title
                }
            );
        } else {
            console.error("Series not found!");
        }
    }

    return (
        <>
            <div className="form-episodes-overlay">
                <div className="form-episodes-content">
                    {/* ----------------------------------DODATI EPIZODE---------------------------------- */}
                    <div className="add-episode-form">
                        <h1>EPIZODA</h1>
                        <h4 className="form-synopis">Unesi informacije za dodavanje epizode</h4>
                        <form onSubmit={handleSubmit} className="add-episode-fields">

                            <div className="add-episodes">
                                <label className="episodes-id" htmlFor="series">Serijal</label>
                                <select
                                    id="series"
                                    className="episodes-dropdown"
                                    value={selectedSeries}
                                    onChange={(e) => setSelectedSeries(e.target.value)}
                                >
                                    <option value="">Izaberite serijal</option>
                                    {truncatedSeries.map((item) => (
                                        <option key={item._id} value={item.title_params}>
                                            {item.title}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="add-episodes">
                                <label className="episodes-id" htmlFor="email">Epizoda</label>
                                <input
                                    placeholder="Unesi epizodu"
                                    type="number"
                                    value={episode}
                                    onChange={(e) => setEpisode(e.target.value)}
                                />
                            </div>

                            <div className="add-episodes">
                                <label className="episodes-id" htmlFor="email">File Code</label>
                                <input
                                    placeholder="Unesi kod"
                                    type="text"
                                    value={fileCode}
                                    onChange={(e) => setFileCode(e.target.value)}
                                />
                            </div>

                            <button type="submit" className="add-episodes-btn">
                                Izbaci Epizodu
                            </button>
                        </form>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Episodes;