import React from "react";
import { useNavigate } from "react-router-dom";
import "./error404.scss";
import Header from "../Mainscreen/components/header/Header";

const Error404 = () => {
    const navigate = useNavigate();

    return (
        <>
            <Header/>
            <div className="error404">
                <div className="error-container">
                    <h1>404</h1>
                    <h2>Stranica nije pronađena</h2>
                    <p>Izgleda da ste zalutali. Stranica koju tražite ne postoji.</p>
                    <button onClick={() => navigate("/")}>Vrati se na početnu</button>
                </div>
            </div>
        </>
    );
};

export default Error404;
