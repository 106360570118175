import React, {useRef, useEffect, useState} from 'react';
import { FaBars, FaTimes } from "react-icons/fa";
import { BiSearchAlt } from "react-icons/bi";
import MovieSearch from "../../../Buttons/searchBtn/movieSearch/MovieSearch";
import {Link, useNavigate} from 'react-router-dom';
import axios from "axios";
import './header.scss';
import { jwtDecode } from "jwt-decode";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import { IoPersonSharp, IoChatbubbleEllipsesSharp  } from "react-icons/io5";
import { FaArrowLeft, FaTiktok } from "react-icons/fa6";
import { SiPatreon } from "react-icons/si";
import { PiInstagramLogoDuotone } from "react-icons/pi";
import { BsFillStopwatchFill } from "react-icons/bs";
import RecentEpisodeForm from "../../../Buttons/recentEpisodeForm/RecentEpisodeForm";
import {TbBrandDiscordFilled} from "react-icons/tb";


const MainHeader = () => {

    const navigate = useNavigate()
    const headerRef = useRef(null);
    const [username, setUsername]  = useState("");
    const [pfp, setPfp] = useState("")
    const [email, setEmail] = useState("")
    const [isMenuOpen, setIsMenuOpen]  = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [isRecentEpisodesModalOpen, setIsRecentEpisodesModalOpen] = useState(false);

    const handleRecentEpisodesClick = () => {
        setIsRecentEpisodesModalOpen(!isRecentEpisodesModalOpen);
    };


    useEffect(() => {
        const checkTokenValidity = () => {
            const token = localStorage.getItem("authToken");
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);  // Using the correct function name
                    const currentTime = Date.now() / 1000; // current time in seconds
                    if (decodedToken.exp < currentTime) {
                        // Token has expired
                        localStorage.removeItem("authToken");
                        localStorage.removeItem("username");
                        localStorage.removeItem("isAdmin");
                        localStorage.removeItem("pfp");
                        localStorage.removeItem("email")
                    } else {
                        const username = localStorage.getItem("username");
                        setUsername(username);
                        const pfp = localStorage.getItem("pfp");
                        setPfp(pfp);
                        const email = localStorage.getItem("email");
                        setEmail(email);
                    }
                } catch (error) {
                    console.error("Error decoding token: ", error);
                    // If there's an error in decoding, it's safe to assume token is invalid
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("username");
                    localStorage.removeItem("isAdmin");
                    localStorage.removeItem("pfp");
                    localStorage.removeItem("email");
                }
            } else {
                console.log("No token")
            }
        };

        checkTokenValidity();
    }, [navigate]);



    const changeMenu = () => {
            setIsMenuOpen(!isMenuOpen);
        }

        const logoutHandler = () => {
            localStorage.removeItem("authToken");
            localStorage.removeItem("username");
            localStorage.removeItem("isAdmin");
            localStorage.removeItem("pfp");
            localStorage.removeItem("email");
            navigate("/login");
        }

    const navigateToRandomAnime = async () => {
        try {
            const { data } = await axios.get('https://balkanflix-server.vercel.app/api/content/randomSeries');

            if (data.success && data.randomSeries) {
                navigate(`/${data.randomSeries.title_params}`);
            } else {
                console.error("No series available");
            }
        } catch (error) {
            console.error("Error fetching random anime", error);
        }
    };

        const openModal = () => {
            setSearchOpen(true);
        };

        const closeModal = () => {
            setSearchOpen(false);
        };

        useEffect(() => {
            const handleOutsideClick = (event) => {
                // Check if the click target is not within the search area
                if (headerRef.current && !headerRef.current.contains(event.target)) {
                    setSearchOpen(false);
                }
            };

            document.addEventListener('mousedown', handleOutsideClick);

            return () => {
                document.removeEventListener('mousedown', handleOutsideClick);
            };
        }, []);

        const handleProfileClick = () => {
            setIsMenuOpen(!isMenuOpen);
        };



        useEffect(() => {
                const fetchUser = async () => {
                    try {
                        if (localStorage.getItem("username")){
                            setUsername(localStorage.getItem("username"));
                            setPfp(localStorage.getItem("pfp"));
                            setEmail(localStorage.getItem("email"));
                        }else {
                            console.log("No auth token")
                        }
                    } catch (error) {
                        console.error("Server error", error);
                    }
                }

                fetchUser();

                const shrinkHeader = () => {
                    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                        headerRef.current.classList.add('shrink');
                    } else {
                        headerRef.current.classList.remove('shrink');
                    }
                }
                    window.addEventListener('scroll', shrinkHeader);
                    return () => {
                    window.removeEventListener('scroll', shrinkHeader);
            };
        }, []);

    const handleRandomAnimeClick = () => {
        navigateToRandomAnime();
    };

        return (
            <div className="header-search-grid">
                <div ref={headerRef} className="main_header">
                    <div className="main_header__wrap container">
                        <div className="nav-content">
                            <div className="user">
                            <span>{username !== "" ?
                                (<span onClick={changeMenu} className="nav-user">
                                    <span className="userInf">
                                        <div style={{
                                            position: "relative",
                                            width: "70px",
                                            height: "70px",
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                        }}>
                                            {/* Profilna slika */}
                                            <img
                                                src={pfp}
                                                alt="Profile"
                                                style={{
                                                    width: "53px",
                                                    height: "53px",
                                                    borderRadius: "50%",
                                                    border: "2px solid green",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                }}
                                            />
                                            <img
                                                src="/Bozicni-venac-min.png" // Putanja do tvoje slike venca
                                                alt="Wreath"
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    left: "0",
                                                    width: "100%", // Slika venca popunjava ceo krug
                                                    height: "100%", // Slika venca popunjava ceo krug
                                                    objectFit: "cover", // Da se slika uveća i popuni krug bez distorzije
                                                }}
                                            />
                                        </div>
                                    </span>
                                    <div className="profile" onClick={handleProfileClick}>
                                        <div className="pfp-holder">
                                            <div className="pfp"></div>
                                        </div>
                                        {isMenuOpen && (
                                            <div className={`dropdown-menu ${isMenuOpen ? 'show' : ''}`}>
                                                <div className="username">
                                                    {username}
                                                </div>
                                                <div className="email">
                                                    {email}
                                                </div>
                                                <div className="options">
                                                    <Link to="/chat">
                                                        <IoChatbubbleEllipsesSharp/> Ćaskanje
                                                    </Link>
                                                    <button onClick={handleRandomAnimeClick}>
                                                        <GiPerspectiveDiceSixFacesRandom/> Nasumično
                                                    </button>
                                                    <button onClick={handleRecentEpisodesClick}>
                                                        <BsFillStopwatchFill/> Poslednje gledano
                                                    </button>
                                                    <Link to="/profil">
                                                        <IoPersonSharp/> Profil
                                                    </Link>
                                                </div>
                                                <div className="social-menu">
                                                    <Link
                                                        to="https://www.patreon.com/c/balkanflix/shop">
                                                        <SiPatreon/>
                                                    </Link>
                                                    <Link
                                                        to="https://www.instagram.com/_balkanflix?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                                                        <PiInstagramLogoDuotone/>
                                                    </Link>
                                                    <Link
                                                        to="https://www.tiktok.com/@balkanflix.official?is_from_webapp=1&sender_device=pc">
                                                        <FaTiktok/>
                                                    </Link>
                                                    <Link to="https://discord.gg/Mqqf8tuEv8">
                                                        <TbBrandDiscordFilled />
                                                    </Link>
                                                </div>
                                                <div className="log-out" onClick={logoutHandler}><FaArrowLeft/> Odjavi
                                                    se
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </span>)
                                : (<Link to="/login" className="login-header-btn">Prijavi se</Link>)}
                            </span>
                            </div>
                        </div>
                        <div className="logo">
                            <img src='/Balkanflix_novogodisnji.png' alt="logo"/>
                            <Link to="/">
                                <div className="bf">
                                    <div className="balkan">Balkan</div>
                                    <div className="flix">Flix</div>
                                </div>
                            </Link>
                        </div>
                        <div className="movie-search">
                        <MovieSearch className="mosc"/>

                            {
                                searchOpen ?
                                    <div onClick={closeModal}>
                                        <BiSearchAlt className="src-loupe"/>
                                    </div>
                                    :
                                    <div onClick={openModal}>
                                        <BiSearchAlt className="src-loupe"/>
                                    </div>
                            }
                        </div>
                        <div className="social">
                            <Link to="https://www.patreon.com/c/balkanflix/shop">
                                <SiPatreon/>
                            </Link>
                            <Link to="https://www.instagram.com/_balkanflix?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                                <PiInstagramLogoDuotone/>
                            </Link>
                            <Link to="https://www.tiktok.com/@balkanflix.official?is_from_webapp=1&sender_device=pc">
                                <FaTiktok/>
                            </Link>
                            <Link to="https://discord.gg/Mqqf8tuEv8">
                                <TbBrandDiscordFilled />
                            </Link>
                        </div>
                    </div>
                    {searchOpen &&
                        <MovieSearch className="movie-search-phone"/>
                    }
                </div>
                {isRecentEpisodesModalOpen &&
                    <RecentEpisodeForm closeModal={handleRecentEpisodesClick}/>
                }
            </div>

        );
}

export default MainHeader;