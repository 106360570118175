import {Link, useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
// import {category} from "../../../Mainscreen/api/tmdbApi";
import Input from "../../../Mainscreen/components/input/Input";
import './movieSearch.scss'
import axios from "axios";

const MovieSearch = props => {

    const navigate = useNavigate();

    const [keyword, setKeyword] = useState(props.keyword ? props.keyword : '');
    const [searchData, setSearchData] = useState([]);
    const [series, setSeries] = useState([]);

    const res = useCallback(async () => {
        if (keyword.length > 0) {
            const { data } = await axios.get("https://balkanflix-server.vercel.app/api/content/seriesSearch");
            setSeries(data.series);
        }
    }, [keyword, setSeries]);
    const fetchData = async (value) => {
        try {
            // Convert the input value to lowercase to ensure case-insensitive comparison
            const lowerCaseValue = value.toLowerCase();

            const results = series.filter((series) => {
                return (
                    lowerCaseValue &&
                    series &&
                    series.title &&
                    series.title.toLowerCase().includes(lowerCaseValue) // Use the lowercase value for comparison
                );
            }).slice(0, 4);
            console.log(results, "Rezultat")
            setSearchData(results);
        } catch (e) {
            console.log(e)
        }
    }

    const handleChange = (value) => {
        setKeyword(value);
        fetchData(value);
    }


    const goToSearch = useCallback(
        () => {
            if (keyword.trim().length > 0) {
                navigate(`/search/${keyword}`);
                setKeyword("");
            }
        },
        [keyword, navigate]
    );

    useEffect(() => {
        res();
        const enterEvent = (e) => {
            e.preventDefault();
            if (e.keyCode === 13) {
                goToSearch();
            }
        }
        document.addEventListener('keyup', enterEvent);
        return () => {
            document.removeEventListener('keyup', enterEvent);
        };
    }, [keyword, goToSearch, res]);

    return (
        <div className={`movie-search ${props.className}`}>
            <Input
                className="input-movies"
                type="text"
                placeholder="Unesi ključne reči"
                value={keyword}
                onChange={(e) => handleChange(e.target.value)}
            />
            <button className="small-src" onClick={goToSearch}>Pretraži</button>
            <div className="search-result">
                {
                    keyword.length > 0 &&
                    searchData.map((data, index) => {
                        return(
                            <Link
                                key={index}
                                to={`/${encodeURIComponent(data.title_params)}`}
                                target="_top"
                                className="src-items"
                            >
                                <div className="search_sugestion_line">
                                    <img src={`https://raw.githubusercontent.com/Strale2006/SlikeStranice/main/${data.img}`} alt="slika" />
                                    {data.title}
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MovieSearch;